/* eslint-disable react/no-danger */
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './more-info-style'
import Title from '../../../../ui/title-detail'
import combineClassNames from '../../../../helpers/combineClassNames'

const MoreInfoView = ({ htmlContent, className }) => {
  const { t } = useTranslation()
  const { moreInfo, content, title } = useStyles()
  return (
    <div className={combineClassNames([moreInfo, className])}>
      <Title
        text={t('screens.product.description')}
        className={title}
      />
      <article className={content} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  )
}

export default MoreInfoView
