import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    padding: [[0, 'var(--ui-spacer-m)', 'var(--ui-spacer-m)']],
    minHeight: '60rem',
    // overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: 'var(--pages-product-grid-template-columns)',
    gridTemplateRows: 'var(--pages-product-grid-template-rows)',
    gridTemplateAreas: 'var(--pages-product-grid-template-areas)',
    columnGap: 'var(--ui-spacer-m)',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: [['var(--ui-spacer-l)', 0, 'var(--ui-spacer-l)']],
      columnGap: 'calc(var(--ui-spacer-s) * 2)',
    },
  },
  images: {
    gridArea: 'images',
    display: 'var(--pages-product-images-display)',
    marginTop: 'var(--pages-product-images-margin-top, 0)',
    marginBottom: 'var(--pages-product-images-margin-bottom, 0)',
    '&:empty': {
      display: 'none',
    },
  },
  attributes: {
    gridArea: 'attributes',
    display: 'var(--pages-product-attributes-display)',
    marginTop: 'var(--pages-product-attributes-margin-top, 0)',
    marginBottom: 'var(--pages-product-attributes-margin-bottom, 0)',
    '&:empty': {
      display: 'none',
    },
  },
  description: {
    gridArea: 'description',
    display: 'var(--pages-product-description-display)',
    marginTop: 'var(--pages-product-description-margin-top, 0)',
    marginBottom: 'var(--pages-product-description-margin-bottom, 0)',
    '&:empty': {
      display: 'none',
    },
  },
  contentGroup: {
    gridArea: 'contentGroup',
    display: 'var(--pages-product-content-group-display)',
    marginTop: 'var(--pages-product-content-group-margin-top, 0)',
    marginBottom: 'var(--pages-product-content-group-margin-bottom, 0)',
    '&:empty': {
      display: 'none',
    },
  },
  recommendations: {
    gridArea: 'recommendations',
    display: 'var(--pages-product-recommendations-display)',
    marginTop: 'var(--pages-product-recommendations-margin-top, 0)',
    marginBottom: 'var(--pages-product-recommendations-margin-bottom, 0)',
    '&:empty': {
      display: 'none',
    },
  },
  recentlyViewed: {
    gridArea: 'recentlyViewed',
    display: 'var(--pages-product-recently-viewed-display)',
    marginTop: 'var(--pages-product-recently-viewed-margin-top, 0)',
    marginBottom: 'var(--pages-product-recently-viewed-margin-bottom, 0)',
    '&:empty': {
      display: 'none',
    },
  },
  reviews: {
    gridArea: 'reviews',
    display: 'var(--pages-product-reviews-display)',
    marginTop: 'var(--pages-product-reviews-margin-top, 0)',
    marginBottom: 'var(--pages-product-reviews-margin-bottom, 0)',
    '&:empty': {
      display: 'none',
    },
  },
  containerLoading: {
    minHeight: '60vh',
  },
  breadcrumb: {
    paddingTop: '3rem',
    marginBottom: '1.7rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      paddingTop: '2rem',
      marginBottom: '1.5rem',
    },
  },
}))

export default useStyles
