import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  A11y,
  Navigation,
  Pagination,
  Zoom,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import useStyles from './product-images-style'
import combineClassNames from '../../../../helpers/combineClassNames'
import { useThemeConfig } from '../../../../hook/use-theme-config'
import placeholderImage from '../../../../assets/images/icon_image_placeholder.png'
import IconZoomIn from '../../../../assets/icons/icon_zoom_in.svg'
import IconZoomOut from '../../../../assets/icons/icon_zoom_out.svg'

const ProductImageView = (props) => {
  const {
    pictures = [],
    loading = false,
    className,
  } = props
  const { getConfig } = useThemeConfig()
  const [mainSwiper, setMainSwiper] = useState()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [zoom, setZoom] = useState(1)
  const placeholder = getConfig('config.placeholderImage', placeholderImage)
  // deprecated theme config:
  // 'config.ui.images.zoom.disableImageZoomDesktop'
  // 'config.ui.images.zoom.disableImageZoomMobile'
  // new theme config:
  // 'config.ui.images.zoom.enabled'
  //  > {
  //  >   "zoom": {
  //  >     "enabled": [true|false]
  //  >   }
  //  >   ==== or ====
  //  >   "zoom": {
  //  >     "enabled": {
  //  >       "mobile": [true|false],
  //  >       "tablet": [true|false],
  //  >       ...[mediaQueryKey]: [true|false]
  //  >     }
  //  >   }
  //  > }

  const zoomSize = getConfig('config.ui.images.zoom.size', 2)

  const styles = useStyles()
  const onZoomChange = (swiper, scale) => {
    setZoom(scale)
  }
  const zoomOut = () => {
    if (!mainSwiper) return
    mainSwiper.zoom.toggle()
  }
  const zoomIn = () => {
    if (!mainSwiper) return
    mainSwiper.zoom.toggle()
  }

  // Reset to first slide when pictures change
  useEffect(() => {
    if (!mainSwiper) return
    if (zoom > 1) zoomOut()
    mainSwiper.slideTo(0, 100)
  }, [pictures])

  return (
    <div className={combineClassNames([styles.productImage, className])}>
      <div className={styles.mainWindow}>
        { loading && <div className={styles.loadingPlaceholder} />}
        { !loading && (
          pictures.length > 0 ? (
            <>
              <Swiper
                style={{
                  '--swiper-navigation-size': '3rem',
                  '--swiper-pagination-bottom': '0',
                }}
                modules={[
                  Navigation,
                  Pagination,
                  A11y,
                  Zoom,
                ]}
                zoom={{
                  maxRatio: zoomSize,
                  toggle: false,
                }}
                navigation
                pagination={{
                  clickable: true,
                  bulletClass: combineClassNames(['swiper-pagination-bullet', styles.paginationBullet]),
                }}
                onSlideChange={(s) => { setCurrentSlide(_.get(s, 'activeIndex', 0)) }}
                onSwiper={setMainSwiper}
                onZoomChange={onZoomChange}
              >
                {
                  _.map(pictures, (pic, index) => (
                    <SwiperSlide key={`product-image-${index}`}>
                      <div className={styles.slideInner}>
                        <div className={combineClassNames(['swiper-zoom-container'])}>
                          <img
                            className={styles.slideImage}
                            src={_.get(pic, 'galleryLarge')}
                            alt={index}
                            loading="lazy"
                          />
                          <div className="swiper-lazy-preloader" role="presentation" />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                }
                <span slot="container-end">
                  <button
                    className={styles.scaleButton}
                    type="button"
                    onClick={zoom > 1 ? zoomOut : zoomIn}
                  >
                    <img src={zoom > 1 ? IconZoomOut : IconZoomIn} alt="" />
                  </button>
                </span>
              </Swiper>
            </>
          ) : (
            <div className={styles.placeholder}>
              <img className={styles.placeholderImage} src={placeholder} alt="Placeholder" />
            </div>
          )
        )}
      </div>
      <div className={styles.boxThumbs}>
        {
          _.map(pictures, ({ webThumb }, thumbIndex) => (
            <button
              key={`images-${thumbIndex}`}
              type="button"
              className={
                combineClassNames([
                  styles.thumb,
                  currentSlide === thumbIndex && styles.thumbSelected,
                ])
              }
              onClick={() => mainSwiper.slideTo(thumbIndex)}
            >
              <img
                src={webThumb}
                alt={thumbIndex}
                className={combineClassNames([styles.thumbImage])}
              />
            </button>
          ))
        }
      </div>
    </div>
  )
}

export default ProductImageView
