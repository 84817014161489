/* eslint-disable no-unused-vars */
/* eslint-disable no-extra-semi */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import toInteger from 'lodash/fp/toInteger'
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import URI from 'urijs'
import {
  cancelRequest,
  useCategories,
  useCurrencies,
  useDepartments,
  useSystemSettings,
  useUser,
} from 'react-omnitech-api'
import {
  useAlert,
  useAnalytics,
  useDineInMiniCart,
  useLink,
  useLocation,
  useMiniCart,
  useModal,
  useOrderMethod,
  useProduct,
  useCompare,
  useThemeConfig,
} from '../../hook'
import {
  getTopLevelCategoryCode,
  isBrowser,
  parseStockLevel,
} from '../../helpers'
import ProductView from './product-view'

function ProductController(props) {
  const {
    id,
    location,
  } = props
  const urlParams = useMemo(() => {
    let search = {}
    if (isBrowser()) {
      search = URI(location.href).search(true)
    }
    return search
  }, [location])

  const alert = useAlert()
  const { categories } = useCategories()
  const { departments } = useDepartments()
  const { location: loc } = useLocation()
  const { navigate, recordNotFound } = useLink()
  const { user } = useUser()
  const {
    compareData,
    addItemToCompare,
    hasMoreThan,
    isOpen,
    clearItemToCompare,
    enableComparisonEcom,
    maxNumberComparisonEcom,
    goToCompareProducts,
  } = useCompare()
  const { openMiniCart: openDineInMiniCart, closeMiniCart: closeDineInMiniCart } = useDineInMiniCart()
  const { openMiniCart, closeMiniCart } = useMiniCart()
  const { getSystemSetting } = useSystemSettings()
  const { currencies } = useCurrencies()
  const currency = _.find(currencies, { isBaseCurrency: true })
  const [pageReady, setPageReady] = useState(false)
  const [addToCartSuccessCount, setAddToCartSuccessCount] = useState(0)

  const fnbEnabled = getSystemSetting('features.fnb.enable')
  const reviewsEnabled = !getSystemSetting('hide_reviews', false)

  // prepare omnitech api
  const { t } = useTranslation()
  const { orderMethod, store } = useOrderMethod()
  const { getConfig, getContentGroup } = useThemeConfig()
  const { trackEvent, getProductParams } = useAnalytics()

  const modal = useModal()

  const {
    preSelectedColorOptionId,
    preSelectedSizeOptionId,
  } = useMemo(() => {
    const colorOptionId = _.get(urlParams, 'colorOptionId')
      || _.get(urlParams, 'color')
    const sizeOptionId = _.get(urlParams, 'sizeOptionId')
      || _.get(urlParams, 'size')
    return {
      preSelectedColorOptionId: _.isNil(colorOptionId) ? null : _.toInteger(colorOptionId),
      preSelectedSizeOptionId: _.isNil(sizeOptionId) ? null : _.toInteger(sizeOptionId),
    }
  }, [urlParams])

  const editGroupUuid = useMemo(() => (
    _.get(urlParams, 'groupUuid')
  ), [urlParams])
  const isEdit = useMemo(() => (
    !_.isEmpty(editGroupUuid)
  ), [editGroupUuid])

  const {
    // ...taskStates,
    addItemsToCartState,
    addonsState,
    backInStockNotificationState,
    cartState: fetchCartForEditState,
    initState,
    noCacheState,
    siblingsState,

    addToCartButtonState,
    loadings,
    // tasks,
    availableQuantity,
    stockLevel,
    product,
    seoMetas,
    productAddons,
    productQuantity,
    selectedColorOptionId,
    selectedSizeOptionId,
    siblings,
    addonsValue,
    addonsTouched,
    isAddonsValid,
    // storeMenuCodes,
    onAddToCart,
    onBackInStockNotification: createBackInStockNotification,
    onFavouriteChange,
    onColorOptionChange,
    onSizeOptionChange,
    onAddonsChange,
    onAddonsValidate,
    onProductQuantityChange,
    addRecentlyViewed,
    getAddonAvailableQuantity,
    getTask,
  } = useProduct(id, {
    cartLinePropertiesGroupUuid: editGroupUuid,
    includes: _.compact([
      'noCache',
      'addons',
      !isEdit && 'siblings',
    ]),
    defaultSelectedColorOptionId: preSelectedColorOptionId,
    defaultSelectedSizeOptionId: preSelectedSizeOptionId,
  })

  // enableBreadCrumb: enable by default;
  const enableBreadCrumb = getConfig('config.pages.product.enableBreadCrumb', true) !== false
  const enableRecentlyViewed = getConfig('config.pages.product.enableRecentlyViewed', false)

  const currentPage = `${_.get(loc, 'page', '/')}${_.get(loc, 'search', '')}`

  // local variable
  const seoTitle = _.get(product, 'title')
  const {
    code: pdpPageContentGroupCode,
    template: pdpPageContentGroupTemplate,
  } = useMemo(() => (
    getContentGroup('config.pages.pdp', 'ecom_pdp_page')
  ), [getContentGroup])
  const { seoDescription, seoMeta } = useMemo(() => {
    let description = ''
    const meta = _.map(seoMetas, (data) => {
      const attribute = _.get(data, 'attribute') || {}
      // define a metakey to keep track of unique meta tags
      const metaKey = attribute.name || attribute.property
      if (metaKey === 'og:description') {
        description = attribute.content
      }
      // API sometimes return seo_meta keyword with property|content attributes
      // so need to clean-up to return name|content attributes
      if (metaKey === 'keywords' && _.isEmpty(data.attribute.name)) {
        attribute.name = 'keywords'
        delete attribute.property
      }
      return {
        ...attribute,
      }
    })
    return {
      seoMeta: meta,
      seoDescription: description,
    }
  }, [seoMetas])
  const seoLinks = [{
    rel: 'canonical',
    href: _.get(product, 'canonicalHref'),
  }]
  const breadcrumb = useMemo(() => {
    const topCategoryCode = getTopLevelCategoryCode({
      topLevelCategory: _.get(orderMethod, 'topLevelCategory'),
      storeCode: _.get(store, 'code', ''),
    })
    const orderMethodInitPage = _.get(orderMethod, 'initPage')
    const category = _.find(categories, { id: _.get(product, 'categoryIds.0') })
    const all = {
      text: t('screens.product.breadcrumb.all'),
      url: _.includes(orderMethodInitPage, 'categories') ? '/categories/' : '/products/',
    }
    const self = {
      text: _.get(product, 'title'),
    }
    let breadcrumbList = []
    const addCategory = ({
      departmentId,
      parentId,
      name,
      code,
    } = {}) => {
      const department = _.find(departments, { id: departmentId })
      const parent = _.find(categories, { id: parentId })
      breadcrumbList = _.concat([{
        text: name,
        url: `/products/?categoryCodeEq=${code}`,
      }], breadcrumbList)
      if (_.isPlainObject(parent)) {
        if (!_.isEmpty(topCategoryCode) && parent.code === topCategoryCode) {
          // skip top level category
        } else {
          addCategory(parent)
        }
      } else {
        const departmentCode = _.get(department, 'code')
        if (departmentCode) {
          breadcrumbList = _.concat(
            [{
              text: _.get(department, 'name'),
              url: `/products/?departmentCodeEq=${departmentCode}`,
            }],
            _.map(breadcrumbList, ({ url, ...others }) => ({
              url: `${url}&departmentCodeEq=${departmentCode}`,
              ...others,
            })),
          )
        }
      }
    }
    addCategory(category)
    return _.concat([all], breadcrumbList, [self])
  }, [product])

  const initProductReady = useMemo(() => (
    !_.includes(loadings, 'init')
  ), [loadings])

  const productReady = useMemo(() => (
    !_.includes(loadings, 'noCache')
  ), [loadings])

  /**
   * get Share URl and Share image URL
   */
  const {
    shareUrl,
    shareImageUrl,
  } = useMemo(() => (
    {
      shareUrl: _.get(product, 'canonicalHref', location.href),
      shareImageUrl: _.get(product, 'colorOptions.0.images.0.versions.webMedium'),
    }
  ), [product])

  const hideAddToCartButton = useMemo(() => _.get(orderMethod, 'pdpHideAddToCartButton', false), [orderMethod])

  const orderMethodCode = useMemo(() => _.get(orderMethod, 'code'), [orderMethod])
  const commerceType = useMemo(() => _.get(orderMethod, 'commerceType'), [orderMethod])

  const isDineIn = useMemo(() => (
    commerceType === 'dineIn'
  ), [commerceType])
  const isDineInMenuMode = useMemo(() => (
    orderMethodCode === 'dineInMenu'
  ), [orderMethodCode])

  /**
   * get price ans stock level with cascade
   */
  const {
    onSale, originalPrice, sellPrice,
  } = useMemo(() => {
    if (!productReady) return {}

    let displayOriginalPrice
    let displaySellPrice
    let displayStockLevel

    switch (true) {
      // selected both color and size, use sku level
      case !_.isUndefined(selectedColorOptionId) && !_.isUndefined(selectedSizeOptionId): {
        const { skus = [] } = product
        const sku = _.find(skus, {
          colorOptionId: selectedColorOptionId,
          sizeOptionId: selectedSizeOptionId,
        })
        displayOriginalPrice = _.get(sku, 'originalPrice')
        displaySellPrice = _.get(sku, 'sellPrice')
        displayStockLevel = _.get(sku, 'stockLevel')
        break
      }
      // only selected color option, use color level
      case !_.isUndefined(selectedColorOptionId): {
        const { colorOptions = [] } = product
        const colorOption = _.find(colorOptions, {
          id: selectedColorOptionId,
        })
        displayOriginalPrice = _.get(colorOption, 'originalPrice')
        displaySellPrice = _.get(colorOption, 'sellPrice')
        displayStockLevel = _.get(colorOption, 'stockLevel')
        break
      }
      // use product level
      default: {
        displayOriginalPrice = _.get(product, 'originalPrice')
        displaySellPrice = _.get(product, 'sellPrice')
        displayStockLevel = _.get(product, 'stockLevel')
        break
      }
    }
    return {
      onSale: displayOriginalPrice !== displaySellPrice,
      originalPrice: displayOriginalPrice,
      sellPrice: displaySellPrice,
      stockLevel: parseStockLevel(displayStockLevel),
    }
  }, [productReady, product, selectedColorOptionId, selectedSizeOptionId])

  const addToCartInProgress = useMemo(() => (
    addItemsToCartState === 'loading'
  ), [addItemsToCartState])
  const createBackInStockNotificationsInProgress = useMemo(() => (
    backInStockNotificationState === 'loading'
  ), [backInStockNotificationState])
  const productAddonsLoading = useMemo(() => (
    addonsState === 'loading'
  ), [addonsState])
  const fetchCartForEditReady = useMemo(() => (
    fetchCartForEditState === 'success'
    || !isEdit
  ), [fetchCartForEditState, isEdit])

  const recommendationOptions = useMemo(() => ({
    products: _.get(product, 'id'),
    colorOptions: selectedColorOptionId || _.get(product, 'defaultColorOptionId'),
    skus: _.get(_.find(_.get(product, 'skus', []), { colorOptionId: _.get(product, 'defaultColorOptionId') }), 'id'),
    trackEventOptions: {
      list: 'PDP Related Products',
    },
  }), [product, selectedColorOptionId])

  const onBackInStockNotification = () => {
    createBackInStockNotification({
      frontendUrlParams: urlParams,
    })
  }

  const onRequestUser = () => {
    const {
      page,
      search,
    } = loc
    const redirectUrl = `${page}${search}`
    navigate(
      '/login/',
      {
        state: {
          redirectUrl,
          // throw back selected order method when come back from login
          // callbackState: { preSelectedOrderMethod: selectedItem, isBlocker },
        },
        replace: true,
      },
    )
  }

  function handleAddToCompare(item, isInStore = false) {
    const objProduct = {
      id: _.get(item, 'id', ''),
      productId: isInStore ? _.get(item, 'productId') : _.get(item, 'product.id'),
      image: isInStore ? _.get(item, 'image')
        : _.get(item, 'colorOption.defaultImage.versions.webThumb', ''),
    }
    addItemToCompare(objProduct)
  }

  function onTrackEvent(objProduct) {
    const colorOptionId = flow(
      get('color'),
      toInteger,
    )(urlParams)
    const colorOption = _.find(objProduct.skus, {
      colorOptionId,
    })

    trackEvent('viewProductDetail', {},
      {
        product,
        price: _.get(colorOption, 'sellPrice'),
        quantity: 1,
        skuCode: _.get(colorOption, 'id'),
        title: seoTitle,
      })
  }

  function handleClickTrackEvent(eventName, productSuggestions) {
    trackEvent(eventName, {}, { product: productSuggestions })
  }

  useEffect(() => {
    if (!enableRecentlyViewed) return
    if (isEdit) return
    if (initState !== 'success') return
    const productId = _.get(product, 'id')
    const url = _.get(product, 'canonicalHref')
    addRecentlyViewed({
      colorOptionId: selectedColorOptionId,
      productId,
      url,
    })
  }, [
    addRecentlyViewed,
    initState,
    isEdit,
    enableRecentlyViewed,
    selectedColorOptionId,
  ])

  useEffect(() => {
    if (
      _.isEmpty(product)
      || !productReady
    ) return
    // Track event
    onTrackEvent(product)
  }, [product, productReady])

  /**
   * All Api calls ready
   */
  useEffect(() => {
    setPageReady(productReady)
  }, [productReady])

  useEffect(() => {
    let error
    if (initState === 'failure') {
      const initTask = getTask('init')
      error = _.get(initTask, 'error', {})
    } else if (noCacheState === 'failure') {
      const noCacheTask = getTask('noCache')
      error = _.get(noCacheTask, 'error', {})
    } else if (siblingsState === 'failure') {
      const siblingsTask = getTask('siblings')
      error = _.get(siblingsTask, 'error', {})
    }
    const generalError = _.get(error, 'generalError', {})
    if (generalError.code === 404) {
      recordNotFound(currentPage)
      return
    }
    if (!_.isEmpty(generalError.message)) {
      alert.show(generalError.message, { state: 'error' })
    }
  }, [initState, noCacheState, siblingsState])
  useEffect(() => {
    const addItemToCartTask = getTask('addItemsToCart')
    if (addItemsToCartState === 'loading') {
      closeMiniCart()
      closeDineInMiniCart()
    } else if (addItemsToCartState === 'success') {
      if (isEdit) {
        navigate('/cart/')
      } else if (isDineIn) {
        setAddToCartSuccessCount((prev) => (prev + 1))
        openDineInMiniCart()
      } else {
        setAddToCartSuccessCount((prev) => (prev + 1))
        openMiniCart()
        const updatedCart = _.get(addItemToCartTask, 'cart')
        const updateCartActions = _.get(addItemToCartTask, 'actions')
        const main = _.find(updateCartActions, { productAddonId: null })
        const mainSku = _.find(product.skus, { id: _.get(main, 'skuId', '////') })
        trackEvent(
          'customerAddToCart',
          {
            eventValue: 1,
          },
          {
            product,
            price: _.get(mainSku, 'sellPrice'),
            quantity: _.get(main, 'quantity', 1),
            skuCode: _.get(mainSku, 'code'),
            title: seoTitle,
            value: _.get(updatedCart, 'priceDetails.cartTotalPrice'),
            currencyCode: _.get(updatedCart, 'currencyCode'),
          },
        )
      }
    } else if (addItemsToCartState === 'failure') {
      const error = _.get(addItemToCartTask, 'error', {})
      const batchActionError = _.get(error, 'batchActionErrors[0]', {})
      const generalError = _.get(error, 'generalError', {})
      const message = _.isEmpty(batchActionError.message)
        ? generalError.message
        : batchActionError.message
      alert.show(message, { state: 'error' })
    }
  }, [addItemsToCartState])
  useEffect(() => {
    if (backInStockNotificationState === 'success') {
      // show modal dialog
      modal.open({
        title: t('screens.product.modalDialog.backInStockNotificationsSuccess.title'),
        message: t('screens.product.modalDialog.backInStockNotificationsSuccess.description'),
        buttons: [
          {
            text: t('screens.product.modalDialog.backInStockNotificationsSuccess.buttons.cancel'),
            onClick: () => modal.close(),
          },
        ],
      })
    } else if (backInStockNotificationState === 'failure') {
      const backInStockNotificationTask = getTask('backInStockNotification')
      const error = _.get(backInStockNotificationTask, 'error', {})
      const generalError = _.get(error, 'generalError', {})
      if (_.get(generalError, 'code') === 401) {
        onRequestUser()
      } else {
        alert.show(generalError.message, { state: 'error' })
      }
    }
  }, [backInStockNotificationState])

  const viewProps = {
    addToCartSuccessCount,
    addToCartButtonState,
    availableQuantity,
    // availableForAddToCart,
    pdpPageContentGroupCode,
    pdpPageContentGroupTemplate,
    addToCartInProgress,
    breadcrumb,
    createBackInStockNotificationsInProgress,
    currency,
    enableBreadCrumb,
    enableRecentlyViewed,
    fetchCartForEditReady,
    fnbEnabled,
    getAddonAvailableQuantity,
    hideAddToCartButton,
    onSale,
    originalPrice,
    pageReady,
    product,
    productReady,
    productAddons,
    productAddonsLoading,
    productQuantity,
    siblings,
    addonsTouched,
    addonsValue,
    initProductReady,
    isAddonsValid,
    isEdit,
    isReadOnly: isDineInMenuMode,
    recommendationOptions,
    reviewsEnabled,
    selectedColorOptionId,
    selectedSizeOptionId,
    sellPrice,
    seoDescription,
    seoMeta,
    seoTitle,
    seoLinks,
    stockLevel,
    shareUrl,
    shareImageUrl,
    user,
    hasMoreThan,
    compareData,
    enableComparisonEcom,
    maxNumberComparisonEcom,
    isOpen,
    goToCompareProducts,
    onAddonsChange,
    onAddonsValidate,
    onBackInStockNotification,
    onColorChange: onColorOptionChange,
    onSizeChange: onSizeOptionChange,
    onAddToCart,
    onAddToCompare: handleAddToCompare,
    onClearCompare: clearItemToCompare,
    onClickTrackEvent: handleClickTrackEvent,
    onProductQuantityChange,
    onFavouriteChange,
    onRequestUser,
  }

  return (
    <ProductView {...viewProps} />
  )
}

export default ProductController
