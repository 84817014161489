import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(() => ({
  container: {
  },
  title: {
  },
}))

export default useStyles
