import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
  },
  dropdown: {
  },
  dropdownLabel: {
    marginBottom: '0.5rem',
    color: theme.colors.text,
  },
  dropdownOptionStoreAddress: {
    fontSize: '1.4rem',
    lineHeight: '1.2em',
    color: theme.colors.gray500,
  },
  stockLevelLabel: {
    display: 'inline-block',
    fontSize: '0.8rem',
    lineHeight: '1em',
    padding: '0.5rem 1rem',
    marginLeft: '0.9rem',
    transform: 'translateY(-0.2rem)',
    border: '1px solid currentColor',
    color: theme.colors.success,
  },
  stockLevelLabelMedium: {
    color: theme.colors.active,
  },
  stockLevelLabelLow: {
    color: theme.colors.alert,
  },
  stockLevelLabelOutOfStock: {
    color: theme.colors.gray300,
  },
  progressContainer: {
    marginBottom: '1.5rem',
  },
  progress: {
    display: 'block',
    width: '100%',
    height: '0.8rem',
    appearance: 'none',
    '&:indeterminate::-moz-progress-bar': {
      width: 0,
    },
    '&[value]': {
      '&::-webkit-progress-bar': {
        backgroundColor: theme.colors.light,
        borderRadius: '0.4rem',
      },
      '&::-moz-progress-bar': {
        backgroundColor: theme.colors.success,
        borderRadius: '0.4rem',
        transition: 'width 200ms',
      },
      '&::-webkit-progress-value': {
        backgroundColor: theme.colors.success,
        borderRadius: '0.4rem',
        transition: 'width 200ms',
      },
    },
  },
  progressMedium: {
    '&[value]::-webkit-progress-value': {
      backgroundColor: theme.colors.active,
    },
    '&[value]::-moz-progress-bar': {
      backgroundColor: theme.colors.active,
    },
  },
  progressLow: {
    '&[value]::-webkit-progress-value': {
      backgroundColor: theme.colors.alert,
    },
    '&[value]::-moz-progress-bar': {
      backgroundColor: theme.colors.alert,
    },
  },
  label: {
    fontSize: '1.2rem',
    color: theme.colors.text,
    marginBottom: '0.5rem',
  },
  desc: {
    fontSize: '1.4rem',
    margin: '0.9rem 0',
    color: theme.colors.gray500,
  },
  inlineError: {
    fontSize: '1.4rem',
    margin: '0.9rem 0',
    color: theme.colors.alert,
  },
}))

export default useStyles
