/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../ui/button'
import CurrencyFormat from '../../../../ui/currency-format'
import FavouriteButton from '../../../../ui/favourite-button'
import LoadingDots from '../../../../ui/loading-dots'
import {
  useThemeConfig,
} from '../../../../hook/use-theme-config'
import combineClassNames from '../../../../helpers/combineClassNames'
import CartIcon from '../../../../assets/icons/icon_cart_white.svg'
import IconEmail from '../../../../assets/icons/icon_email.inline.svg'
import useStyles from './product-card-add-style'

const ProductCardAddView = ({
  addToCartButtonState,
  availableQuantity,
  className,
  createBackInStockNotificationsInProgress,
  favourite,
  favouritesEnabled,
  hideAddToCartButton,
  hideSizeVariant,
  isOutOfStock,
  isReadOnly,
  onAddToCart,
  onBackInStockNotification,
  onFavouriteChange,
  productReady,
  selectedColorOptionId,
  sellPrice,
  sizeOptions,
  sku,
}) => {
  // hook
  const { t } = useTranslation()
  const { getConfig } = useThemeConfig()
  const noPrice = _.isNil(sellPrice)
  const size = _.find(sizeOptions, { id: sku.sizeOptionId })
  const enableSubtotalPrice = getConfig('config.pages.product.enableSubtotalPrice', true) !== false
  const enableStockInfo = getConfig('config.pages.product.enableStockInfo', true) !== false
  const enableSkuCode = getConfig('config.pages.product.enableSkuCode', true) !== false
  const enableBackInStockNotification = getConfig('config.pages.product.enableBackInStockNotification', false)

  const addToCartLabel = useMemo(() => (
    t('screens.product.cart.add', { context: addToCartButtonState, availableQuantity })
  ), [addToCartButtonState])
  const isAddToCartButtonDisabled = useMemo(() => (
    !_.includes([
      'update',
      'normal',
    ], addToCartButtonState)
  ), [addToCartButtonState])

  const styles = useStyles()

  const handleFavouriteChange = (data) => {
    if (!_.isFunction(onFavouriteChange)) return
    onFavouriteChange({ colorOptionId: selectedColorOptionId, ...data })
  }

  return (
    <div className={combineClassNames([styles.cardAdd, className])}>
      <div className={styles.details}>
        {
          !hideSizeVariant && (
            <dl className={styles.line}>
              <dt className={styles.label}>{t('screens.product.cart.size')}</dt>
              <dd className={styles.value}>{_.get(sku, 'meta.detailedSize', _.get(size, 'name', '-'))}</dd>
            </dl>
          )
        }
        {
          enableSkuCode && (
            <dl className={styles.line}>
              <dt className={styles.label}>{t('screens.product.cart.code')}</dt>
              <dd className={styles.value}>{_.get(sku, 'code', '-')}</dd>
            </dl>
          )
        }
        {
          enableStockInfo && (
            <dl className={styles.line}>
              <dt className={styles.label}>{t('screens.product.cart.availability')}</dt>
              <dd className={styles.value}>
                {
                  productReady ? (
                    t(`screens.product.cart.${isOutOfStock ? 'outOfStock' : 'inStock'}`)
                  ) : (
                    <LoadingDots className={styles.loadingDots} />
                  )
                }
              </dd>
            </dl>

          )
        }
        {
          enableSubtotalPrice && (
            <dl className={styles.line}>
              <dt className={styles.label}>{t('screens.product.cart.price')}</dt>
              <dd className={styles.value}>
                {
                  !productReady && <LoadingDots className={styles.loadingDots} />
                }
                {
                  productReady && (
                    noPrice
                      ? '-'
                      : (
                        <CurrencyFormat
                          className={styles.price}
                          value={sellPrice}
                        />
                      ))
                }
              </dd>
            </dl>
          )
        }
      </div>
      {
        !isReadOnly
        && !hideAddToCartButton
        && (
          <div className={styles.buttonContainer}>
            {
              enableBackInStockNotification
              && addToCartButtonState === 'backInStockNotification'
                ? (
                  <Button
                    dark
                    iconInline
                    iconImage={IconEmail}
                    className={styles.backInStockNotificationButton}
                    iconClassName={styles.backInStockNotificationButtonIcon}
                    disabled={createBackInStockNotificationsInProgress}
                    loading={createBackInStockNotificationsInProgress}
                    classNameDisabled={styles.backInStockNotificationButtonDisabled}
                    text={addToCartLabel}
                    onClick={onBackInStockNotification}
                  />
                )
                : (
                  <Button
                    dark
                    iconImage={CartIcon}
                    className={styles.addToCartButton}
                    iconClassName={styles.addToCartButtonIcon}
                    disabled={isAddToCartButtonDisabled}
                    classNameDisabled={styles.addToCartButtonDisabled}
                    text={addToCartLabel}
                    onClick={() => _.isFunction(onAddToCart) && onAddToCart({ skuId: _.get(sku, 'id') })}
                  />
                )
            }
          </div>
        )
      }
      {
        favouritesEnabled && (
          <div className={styles.buttonContainer}>
            <FavouriteButton
              iconButton
              label={t('screens.product.cart.favourites')}
              colorOptionId={selectedColorOptionId}
              favourite={favourite}
              onChange={handleFavouriteChange}
            />
          </div>
        )
      }
    </div>
  )
}

export default ProductCardAddView
