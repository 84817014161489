import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  specifications: {
    maxWidth: '77rem',
  },
  titleStyle: {
    fontSize: '2.4rem',
    lineHeight: '2.8rem',
    margin: '0 0 1.2rem',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      fontSize: '3.6rem',
      lineHeight: '4rem',
    },
  },
}))

export default useStyles
