import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './specifications-card-style'
import Title from '../../../../ui/title-detail'
import ProductDetailAttributes from '../../../../ui/product-detail-attributes'
import combineClassNames from '../../../../helpers/combineClassNames'

const SpecificationsView = ({ className, detailAttributes }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  return (
    <div className={combineClassNames([styles.specifications, className])}>
      <Title
        text={t('screens.product.specifications.title')}
        className={styles.titleStyle}
      />
      <ProductDetailAttributes
        data={detailAttributes}
      />
    </div>
  )
}

export default SpecificationsView
