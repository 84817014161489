import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './product-style'
import {
  Layout,
  Breadcrumb,
  ModalCompare,
  SEO, ContentGroup,
} from '../../ui'
import {
  Specifications,
  MoreInfo,
  Suggestions,
  RecentlyViewed,
  Reviews,
  ProductImages,
  ProductDetails,
} from './components'

function ProductView({
  addonsTouched,
  addonsValue,
  addToCartButtonState,
  addToCartInProgress,
  addToCartSuccessCount,
  availableQuantity,
  availableForAddToCart,
  breadcrumb,
  compareData,
  createBackInStockNotificationsInProgress,
  currency,
  enableBreadCrumb,
  enableComparisonEcom,
  enableRecentlyViewed,
  fetchCartForEditReady,
  fnbEnabled,
  getAddonAvailableQuantity,
  goToCompareProducts,
  hideAddToCartButton,
  initProductReady,
  isAddonsValid,
  isEdit,
  isFavourited,
  isOpen,
  isReadOnly,
  maxNumberComparisonEcom,
  onAddonsChange,
  onAddonsValidate,
  onAddToCart,
  onBackInStockNotification,
  onAddToCompare,
  onClearCompare,
  onColorChange,
  onFavouriteChange,
  onProductQuantityChange,
  onRequestUser,
  onSale,
  onSizeChange,
  onStockAvailibilityStoreChange,
  onUpdateFavourite,
  onViewSizeColorOptions,
  originalPrice,
  pdpPageContentGroupCode,
  pdpPageContentGroupTemplate,
  product,
  productAddons,
  productAddonsLoading,
  productQuantity,
  productReady,
  recommendationOptions,
  reviews,
  reviewsEnabled,
  selectedColorOptionId,
  selectedSizeOptionId,
  sellPrice,
  seoDescription,
  seoLinks,
  seoMeta,
  seoTitle,
  shareImageUrl,
  shareUrl,
  siblings,
  stockAvailibility,
  stockAvailibilityStoreCode,
  stockCountersLoading,
  stockLevel,
}) {
  const styles = useStyles()
  const { t } = useTranslation()

  const {
    colorOptions,
  } = product

  const selectedColorOption = _.find(colorOptions, { id: selectedColorOptionId })
  const detailAttributes = _.get(product, 'productDetailAttributes', [])

  return (
    <Layout>
      {/* passing page specific data for SEO */}
      {
        !_.isEmpty(seoTitle)
        && (
        <SEO
          description={seoDescription || _.get(product, 'detailsPlainText')}
          title={seoTitle}
          meta={seoMeta}
          link={seoLinks}
          img={_.get(product, 'colorOptions[0].images[0].versions.webMedium')}
          // PDP - viewProductDetail
          linkedData={[{
            '@context': 'http://schema.org',
            '@type': 'Product',
            aggregateRating: _.get(product, 'reviewsAverageRating', 0) === 0 ? undefined : {
              '@type': 'AggregateRating',
              ratingValue: _.toString(_.get(product, 'reviewsAverageRating', 0)),
              reviewCount: _.toString((reviews || []).length),
            },
            description: _.get(product, 'detailsPlainText'),
            name: _.get(product, 'title'),
            image: _.map(_.get(selectedColorOption, 'images', []), 'versions.galleryLarge'),
            offers: {
              '@type': 'Offer',
              availability: _.isNull(stockLevel) || stockLevel > 0
                ? 'https://schema.org/InStock'
                : 'https://schema.org/SoldOut',
              price: sellPrice,
              priceCurrency: _.get(currency, 'code'),
            },
            review: (reviews || []).length === 0 ? undefined : _.map(reviews, (r) => ({
              '@type': 'Review',
              author: r.user.alias,
              datePublished: r.createdAt,
              reviewBody: r.comment,
              // name: "Not a happy camper",
              reviewRating: {
                '@type': 'Rating',
                bestRating: '5',
                ratingValue: r.rating.toString(),
                worstRating: '1',
              },
            })),
          }]}
        />
        )
      }
      {
        enableBreadCrumb && (
          <Breadcrumb classN={styles.breadcrumb} links={breadcrumb} />
        )
      }
      <div className={styles.containerFluid}>
        <ProductImages
          pictures={_.map(_.get(selectedColorOption, 'images', []), 'versions')}
          loading={!initProductReady}
          className={styles.images}
        />
        <ProductDetails
          key={`product_details_${addToCartSuccessCount}`}
          addonsValue={addonsValue}
          addonsTouched={addonsTouched}
          addToCartButtonState={addToCartButtonState}
          addToCartInProgress={addToCartInProgress}
          availableQuantity={availableQuantity}
          availableForAddToCart={availableForAddToCart}
          createBackInStockNotificationsInProgress={createBackInStockNotificationsInProgress}
          currency={currency}
          fetchCartForEditReady={fetchCartForEditReady}
          fnbEnabled={fnbEnabled}
          getAddonAvailableQuantity={getAddonAvailableQuantity}
          hideAddToCartButton={hideAddToCartButton}
          initProductReady={initProductReady}
          isAddonsValid={isAddonsValid}
          isEdit={isEdit}
          isFavourited={isFavourited}
          isReadOnly={isReadOnly}
          onAddonsChange={onAddonsChange}
          onAddonsValidate={onAddonsValidate}
          onAddToCart={onAddToCart}
          onBackInStockNotification={onBackInStockNotification}
          onColorOptionSelect={onColorChange}
          onFavouriteChange={onFavouriteChange}
          onProductQuantityChange={onProductQuantityChange}
          onSale={onSale}
          onSizeOptionSelect={onSizeChange}
          onStockAvailibilityStoreChange={onStockAvailibilityStoreChange}
          onUpdateFavourite={onUpdateFavourite}
          onViewSizeColorOptions={onViewSizeColorOptions}
          originalPrice={originalPrice}
          product={product}
          productAddons={productAddons}
          productAddonsLoading={productAddonsLoading}
          productQuantity={productQuantity}
          productReady={productReady}
          reviewsEnabled={reviewsEnabled}
          selectedColorOptionId={selectedColorOptionId}
          selectedSizeOptionId={selectedSizeOptionId}
          sellPrice={sellPrice}
          shareImageUrl={shareImageUrl}
          shareUrl={shareUrl}
          siblings={siblings}
          stockAvailibility={stockAvailibility}
          stockAvailibilityStoreCode={stockAvailibilityStoreCode}
          stockCountersLoading={stockCountersLoading}
          stockLevel={stockLevel}
        />
        { !_.isEmpty(detailAttributes) && (
          <Specifications className={styles.attributes} detailAttributes={detailAttributes} />
        )}
        {/* <InfoBox title={t('screens.product.description')} /> */}
        { _.get(product, 'detailsPlainText') && (
          <MoreInfo className={styles.description} htmlContent={_.get(product, 'details')} />
        )}
        {/* <div className={styles.container}> */}
        <div className={styles.contentGroup}>
          <ContentGroup
            code={pdpPageContentGroupCode}
            template={pdpPageContentGroupTemplate}
          />
        </div>
        <Suggestions
          key={`product_${_.get(product, 'id')}_recommendations`}
          title={t('screens.product.like')}
          className={styles.recommendations}
          {...recommendationOptions}
        />
        {
          enableRecentlyViewed && (
            <RecentlyViewed
              key={`product_${_.get(product, 'id')}_recently_viewed_products`}
              className={styles.recentlyViewed}
              title={t('screens.product.recentlyViewed')}
              excludedProducts={[_.get(product, 'id')]}
            />
          )
        }
        {
          initProductReady && reviewsEnabled && (
            <Reviews
              id={_.get(product, 'id')}
              className={styles.reviews}
              onRequestUser={onRequestUser}
            />
          )
        }
      </div>
      {
        enableComparisonEcom
        && (
        <ModalCompare
          compareData={compareData}
          onAddToCompare={onAddToCompare}
          goToCompareProducts={goToCompareProducts}
          t={t}
          isOpen={isOpen}
          onClearCompare={onClearCompare}
          maxNumberComparisonEcom={maxNumberComparisonEcom}
        />
        )
      }
    </Layout>
  )
}

export default ProductView
