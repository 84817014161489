import _ from 'lodash'
import React from 'react'
import useStyles from './recently-viewed-style'
import combineClassNames from '../../../../helpers/combineClassNames'
import Title from '../../../../ui/title-detail'
import RecentlyViewedProducts from '../../../../ui/recently-viewed-products'

const RecentlyViewedView = ({ title, ...rest }) => {
  const styles = useStyles()
  return (
    <div
      className={
        combineClassNames([
          styles.container,
          _.get(rest, 'className'),
        ])
      }
    >
      <RecentlyViewedProducts
        {...rest}
        ListHeaderComponent={(
          <Title
            className={styles.title}
            text={title}
          />
        )}
      />
    </div>
  )
}

export default RecentlyViewedView
