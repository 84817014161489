import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    marginTop: `${theme.doubleGap}rem`,
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      marginRight: '8rem',
    },
    '&:empty': {
      display: 'none',
    },
  },
  title: {
    margin: '0 0 1.2rem',
  },
}))

export default useStyles
