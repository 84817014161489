import _ from 'lodash'
import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
import {
  cancelRequest,
  useReviews,
  useUser,
} from 'react-omnitech-api'
import { useAlert } from '../../../../hook/use-alert'
import ReviewsView from './reviews-view'

function ReviewsController(props) {
  const {
    id,
    onRequestUser,
    ...rest
  } = props

  const alert = useAlert()
  const { user } = useUser()
  const [reviews, setReviews] = useState({})
  const [nextReview, setNextReview] = useState(null)
  const [hasMoreReview, setHasMoreReview] = useState(false)
  const [reviewsReady, setReviewsReady] = useState(false)

  // prepare omnitech api
  const { fetchReviewsByProductID, createReview } = useReviews()

  /**
   * fetchReviewsApi
   * get reviews data from API
   */
  const fetchReviewsApi = useCallback(async () => {
    try {
      // api call option
      const option = {
        includes: [
          'user_default',
        ].join(','),
      }
      // call api
      const {
        reviews: data,
        next,
      } = await fetchReviewsByProductID(id, option)
      setReviews(data)
      setHasMoreReview(_.isFunction(next))
      setNextReview(() => next)
    } catch (error) {
      // const generalError = _.get(error, 'generalError', {})
      // TODO: send to rollbar
      // do nothing
      // alert.show(generalError.message)
    } finally {
      setReviewsReady(true)
    }
  }, [fetchReviewsByProductID, id])

  const onNextReview = useCallback(async () => {
    setReviewsReady(false)
    try {
      const { reviews: data, next } = await nextReview(id)
      setReviews((prev) => _.concat(prev, data))
      setHasMoreReview(_.isFunction(next))
      setNextReview(() => next)
    } catch (error) {
      // const generalError = _.get(error, 'generalError', {})
      // TODO: send to rollbar
      // do nothing
      // alert.show(generalError.message)
    } finally {
      setReviewsReady(true)
    }
  }, [nextReview, id])

  const onCreateReview = useCallback(async (review) => {
    if (!id) return
    if (_.isEmpty(user)) {
      if (_.isFunction(onRequestUser)) {
        onRequestUser()
      }
      return
      // navigate(
      //   '/login/',
      //   {
      //     redirectUrl: _.get(product, 'canonicalHref'),
      //     replace: true,
      //   },
      // )
    }

    if (reviewsReady) {
      setReviewsReady(false)
      try {
        const option = {
          includes: [
            'user_default',
          ].join(','),
        }
        const {
          review: data,
        } = await createReview({
          data: { review: { productId: id, ...review } },
          ...option,
        })

        const userInclude = _.findIndex(reviews, ['user.id', _.get(data, 'user.id')])
        if (userInclude >= 0) {
          const reviewsClone = _.clone(reviews)
          _.remove(reviewsClone, (obj) => _.get(data, 'user.id') === _.get(obj, 'user.id'))
          setReviews(_.concat([data], reviewsClone))
        } else {
          setReviews((prev) => _.concat([data], prev))
        }
      } catch (error) {
        const generalError = _.get(error, 'generalError', {})
        alert.show(generalError.message, { state: 'error' })
      } finally {
        setReviewsReady(true)
      }
    }
  }, [createReview, reviewsReady])

  /**
   * load reviews
   */
  useEffect(() => {
    fetchReviewsApi()

    return function fetchReviewsApiCleanUp() {
      cancelRequest.cancelAll([
        'fetchReviewsByProductID',
      ])
    }
  }, [fetchReviewsApi])

  const viewProps = {
    reviews,
    user,
    hasMoreReview,
    onCreateReview,
    onNextReview,
    ...rest,
  }

  return (
    <ReviewsView {...viewProps} />
  )
}

export default ReviewsController
