/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import {
  useSystemSettings,
  useUser,
} from 'react-omnitech-api'
import CustomLabels from '../../../../ui/custom-labels'
import Button from '../../../../ui/button'
import Link from '../../../../ui/link'
import LoadingDots from '../../../../ui/loading-dots'
import PriceList from '../../../../ui/price-list'
import ProductAddonsPlaceholder from '../../../../ui/product-addons-placeholder'
import Rating from '../../../../ui/rating'
import SocialMedia from '../../../../ui/social-media'
import InputQuantity from '../../../../ui/input-quantity'
import ContentGroup from '../../../../ui/content-group'
import {
  combineClassNames,
  isNotNullOrUndefined,
  parseStockLevel,
} from '../../../../helpers'
import iconBuilder from '../../../../helpers/iconBuilder'
import {
  useThemeConfig,
} from '../../../../hook/use-theme-config'
import useCustomerRankSettings from '../../../../hook/use-customer-rank-settings'
import ArrowFat from '../../../../assets/icons/icon_solid_arrow.svg'
import ProductAddons from '../product-addons'
import ProductCardAdd from '../product-card-add/product-card-add-view'
import ColorPopoverView from '../color-popover/color-popover-view'
import AvailabilityInStore from '../availability-in-store'
import useStyles from './product-details-style'

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

const WhatsappButton = (props) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const {
    className,
    shareUrl,
    phoneNumber,
  } = props
  const onClick = () => {
    const text = t('screens.product.whatsappAma.text', { link: shareUrl })
    window.open(
      `https://wa.me/${phoneNumber}?text=${text}`,
      '_blank',
    )
  }
  return (
    <div className={combineClassNames([styles.whatsappButtonContainer, className])}>
      <Button
        border
        iconImage={iconBuilder('whatsapp')}
        iconClassName={combineClassNames([styles.whatsappButtonIcon])}
        text={t('screens.product.whatsappAma.label')}
        onClick={onClick}
      />
    </div>
  )
}

const ProductDetailView = ({
  addonsTouched,
  addonsValue,
  addToCartButtonState,
  addToCartInProgress,
  availableQuantity,
  availableForAddToCart,
  createBackInStockNotificationsInProgress,
  currency,
  displayAddonsErrors,
  fetchCartForEditReady,
  fnbEnabled,
  getAddonAvailableQuantity,
  hideAddToCartButton,
  initProductReady,
  isAddonsValid,
  isEdit,
  isReadOnly,
  onAddonsChange,
  onAddonsValidate,
  onAddToCart,
  onBackInStockNotification,
  onColorOptionSelect,
  onFavouriteChange,
  onProductQuantityChange,
  onSizeOptionSelect,
  originalPrice,
  product = {},
  productAddons,
  productAddonsLoading,
  productQuantity,
  productReady,
  reviewsEnabled,
  selectedColorOptionId,
  selectedSizeOptionId,
  sellPrice,
  shareImageUrl,
  shareUrl,
  siblings,
  stockLevel,
}) => {
  // hook
  const { t } = useTranslation()
  const { getSystemSetting } = useSystemSettings()
  const { default: defaultRank } = useCustomerRankSettings()
  const {
    loyaltyInformation,
  } = useUser()
  const { getConfig, getContentGroup } = useThemeConfig()
  const styles = useStyles()

  const {
    title,
    colorOptions,
    sizeOptions,
    skus,
    id: productId,
  } = product

  const colorOptionVariantType = _.get(product, 'colorOptionVariantType', {})
  const sizeOptionVariantType = _.get(product, 'sizeOptionVariantType', {})
  const selectedColorOption = _.find(colorOptions, { id: selectedColorOptionId }) || {}
  const selectedSku = _.find(skus, {
    colorOptionId: selectedColorOptionId,
    sizeOptionId: selectedSizeOptionId,
  }) || _.first(_.filter(skus, {
    colorOptionId: selectedColorOptionId,
  })) || _.first(skus) || {}
  const haveSiblings = !_.isEmpty(_.get(product, 'meta.siblingsProductCodes', []))
  const isOutOfStock = !_.isEmpty(siblings)
    ? _.sum(
      _.flatMap(
        siblings,
        ({ skus: siblingSkus }) => _.map(
          siblingSkus,
          ({ stockLevel: siblingStockLevel }) => parseStockLevel(siblingStockLevel),
        ),
      ),
    ) <= 0
    : parseStockLevel(stockLevel) <= 0
  const reviewsCount = _.get(product, 'reviewsCount', 0)
  const sizeGuideUrl = _.get(selectedColorOption, 'meta.sizeGuideUrl')

  const minimumBundlePrice = _.get(selectedSku, 'meta.priceDisplayMode') === 'minimum_bundle_price'
    ? _.get(selectedSku, 'meta.minimumBundlePrice', null)
    : null
  const currentRank = _.get(loyaltyInformation, 'currentCustomerRank.code', defaultRank)
  const memberPrice = haveSiblings
    ? _.min(
      _.flatMap(
        siblings,
        ({ skus: siblingSkus }) => (
          _.map(
            _.compact(_.map(siblingSkus, `meta.memberPrice.${_.camelCase(currentRank)}`)),
            _.toNumber,
          )
        ),
      ),
    )
    : _.get(selectedSku || _.first(skus), `meta.memberPrice.${_.camelCase(currentRank)}`)
  const priceSuffix = !isEdit && (haveSiblings || _.isNumber(minimumBundlePrice)) && t('screens.product.price.up')
  const lowestSellPriceSibling = _.reduce(siblings, (result, sibling) => {
    const { skus: siblingSkus } = sibling
    if (
      _.toNumber(_.get(result, 'skus.0.sellPrice', 0))
      > _.toNumber(_.get(_.first(siblingSkus), 'sellPrice', 0))
    ) {
      result = sibling
    }
    return result
  }, _.first(siblings))

  let displaySellPrice = sellPrice
  if (haveSiblings) displaySellPrice = _.get(lowestSellPriceSibling, 'skus.0.sellPrice')
  if (_.isNumber(minimumBundlePrice)) displaySellPrice = minimumBundlePrice
  let displayOriginalPrice = originalPrice
  if (haveSiblings) displayOriginalPrice = _.get(lowestSellPriceSibling, 'skus.0.originalPrice')
  if (_.isNumber(minimumBundlePrice)) displayOriginalPrice = null
  let displayMemberPrice = memberPrice
  if (_.isNumber(minimumBundlePrice)) displayMemberPrice = null

  const selectedSibling = _.find(addonsValue, ['productAddonId', null])

  const favouritesEnabled = !getSystemSetting('hide_favourites', false)
  const enableQuantityInput = getConfig('config.pages.product.enableQuantityInput')
  const enableQuantityInputValuePicker = getConfig('config.pages.product.enableQuantityInputValuePicker', false)
  const quantityInputValueEditMode = getConfig(
    'config.pages.product.quantityInputValueEditMode',
    enableQuantityInputValuePicker ? 'picker' : 'default',
  )

  // check for whatsapp sharing link
  const whatsappAma = getConfig('config.pages.product.whatsappButton', {
    enable: false,
    phoneNumber: '',
  })

  const enableStockAvailability = getConfig('config.pages.product.stockAvailability.enable', false)

  const pdpDisclaimerContentGroup = getContentGroup('config.pages.product.disclaimer')
  // use fnbEnabled as fallback
  const showQuantityInput = _.isBoolean(enableQuantityInput) ? enableQuantityInput : fnbEnabled

  const [isAddonsValidState, setIsAddonsValidState] = React.useState(isAddonsValid)
  const onAddonsUpdate = (newValue) => {
    setIsAddonsValidState(_.get(newValue, 'isValidCustomerRemark'))
  }
  React.useEffect(() => {
    setIsAddonsValidState(isAddonsValid)
  }, [isAddonsValid])

  // TODO Open guide
  // button above coment until we do this task
  // https://docs.google.com/spreadsheets/d/1rFB6Ojd2jqsipYoXc15K2J9wAqj_J12igXEHhVGGWZ8/edit?disco=AAAAKDw4wXs
  return (
    <>
      {
        !initProductReady && (
          <>
            <div className={styles.productTitlePlaceholder} />
            <div className={styles.pricePlaceholder} />
            <div className={styles.ratingPlaceholder} />
            <div className={styles.colorsBox}>
              <div className={styles.variantNamePlaceholder} />
              <div className={styles.optionsContainer}>
                {
                  _.times(isEdit ? 1 : 9, (index) => (
                    <span key={`variantItemPlaceholder_${index}`} className={styles.colorOptionsItemPlaceholder} />
                  ))
                }
              </div>
            </div>
            <div className={styles.colorsBox}>
              <div className={styles.variantNamePlaceholder} />
              <div className={styles.optionsContainer}>
                {
                  _.times(isEdit ? 1 : 4, (index) => (
                    <span key={`variantItemPlaceholder_${index}`} className={styles.sizeOptionsItemPlaceholder} />
                  ))
                }
              </div>
            </div>
            <div className={styles.productAddPlaceholder} />
          </>
        )
      }
      {
        initProductReady && (
          <h1 className={styles.productTitle}>{title}</h1>
        )
      }
      {
        _.has(pdpDisclaimerContentGroup, 'code')
        && (
          <div className={styles.disclaimerContentGroup}>
            <ContentGroup {...pdpDisclaimerContentGroup} />
          </div>
        )
      }
      {
        (!productReady && initProductReady)
        || (haveSiblings && productAddonsLoading) // wait for siblings price
          ? (
            // FL: Show price loader before second product api call done
            <div className={combineClassNames([styles.priceStyle, styles.priceLoadingDots])}>
              <LoadingDots />
            </div>
          ) : (
            <div className={styles.priceStyle}>
              <PriceList
                currency={currency}
                // Show price for member if rank price provided by sku.meta
                items={[
                  {
                    ...(isNotNullOrUndefined(displayMemberPrice) && { label: t('screens.product.price.nonMember') }),
                    sellPrice: displaySellPrice,
                    originalPrice: displayOriginalPrice,
                    suffix: priceSuffix,
                  },
                  ...isNotNullOrUndefined(displayMemberPrice)
                    ? [{
                      label: t('screens.product.price.member'),
                      sellPrice: displayMemberPrice,
                      suffix: priceSuffix,
                    }] : [],
                ]}
              />
            </div>
          )
      }
      <div className={styles.customLabels}>
        <CustomLabels
          customLabels={_.get(selectedColorOption, 'activeCustomLabels', [])}
          stockLevel={_.get(product, 'stockLevel', null)}
          frontendPosition="1"
        />
      </div>
      {
        initProductReady && reviewsEnabled && (
          <div className={styles.rating}>
            <div className={styles.rateBox}>
              <Rating rate={_.get(product, 'reviewsAverageRating')} editing={false} />
            </div>
            <AnchorLink to="#reviews">
              <span className={styles.anchorReviewStyle}>
                {
                reviewsCount === 0
                  && t('screens.product.reviewsAverageRatingZero')
              }
              </span>
            </AnchorLink>
          </div>
        )
      }
      {!colorOptionVariantType.hideVariant && (
        <div className={styles.colorsBox}>
          <h5>{colorOptionVariantType.name}</h5>
          <div className={styles.optionsContainer}>
            {!isEdit && _.map(colorOptions, (color) => (
              <span className={styles.optionsItem} key={`color-${color.id}`}>
                <ColorPopoverView
                  colorOption={color}
                  onClick={() => {
                    onColorOptionSelect(color.id)
                  }}
                  isSelected={color.id === selectedColorOptionId}
                />
              </span>
            ))}
            {isEdit && (
              <span className={styles.optionsItem}>
                <ColorPopoverView
                  colorOption={_.find(colorOptions, { id: selectedColorOptionId })}
                  isSelected
                />
              </span>
            )}
          </div>
        </div>
      )}
      {!sizeOptionVariantType.hideVariant && (
        <div className={styles.sizesBox}>
          <div className={styles.sizeTitle}>
            <h5>{sizeOptionVariantType.name}</h5>

            {sizeGuideUrl && (
              <Link
                gatsbyLink
                to={sizeGuideUrl}
                className={styles.linkViewGuide}
              >
                <span>{t('screens.product.sizeGuide')}</span>
                <img src={ArrowFat} alt={t('screens.product.sizeGuide')} />
              </Link>
            )}
          </div>
          <div className={styles.optionsContainer}>
            {!isEdit && _.map(sizeOptions, (size) => {
              const sku = _.find(skus, {
                sizeOptionId: size.id,
                colorOptionId: selectedColorOptionId,
              })
              const isSkuOutOfStock = _.get(sku, 'stockLevel', 0) <= 0
              if (_.isEmpty(sku)) return null
              return (
                <span
                  className={combineClassNames([
                    styles.optionsItem,
                    styles.sizeStyle,
                    selectedSizeOptionId === size.id && styles.sizeSelectedStyle,
                    isSkuOutOfStock && styles.sizeOutOfStock,
                  ])}
                  key={`size-${size.id}`}
                  onClick={() => {
                    onSizeOptionSelect(size.id)
                  }}
                  onKeyDown={() => {
                    onSizeOptionSelect(size.id)
                  }}
                >
                  {size.name}
                </span>
              )
            })}
            {isEdit && (
              <span
                className={combineClassNames([
                  styles.optionsItem,
                  styles.sizeStyle,
                  styles.sizeSelectedStyle,
                  // styles.sizeDisabled,
                ])}
              >
                {_.get(_.find(sizeOptions, { id: selectedSizeOptionId }), 'name', '')}
              </span>
            )}
          </div>
        </div>
      )}
      {
        enableStockAvailability
        && !productAddonsLoading
        && (
          <AvailabilityInStore
            className={styles.availabilityInStore}
            productId={_.get(selectedSibling, 'addonId', productId)}
            colorOptionId={selectedColorOptionId}
            sizeOptionId={selectedSizeOptionId}
            skuId={_.get(selectedSibling, 'skuId')}
            siblingsProductCodes={
              haveSiblings
              && _.isEmpty(selectedSibling)
              && _.get(product, 'meta.siblingsProductCodes', [])
            }
          />
        )
      }
      {/* addons */}
      {
        productAddonsLoading && (
          <div className={styles.productAddons}>
            <ProductAddonsPlaceholder />
          </div>
        )
      }
      { React.useMemo(() => {
        if (_.isEmpty(productAddons)) return null
        return (
          <div className={styles.productAddons}>
            <ProductAddons
              // ref={productAddonsRef}
              currency={currency}
              productAddons={productAddons}
              value={addonsValue}
              touched={addonsTouched}
              isEdit={isEdit}
              // disabled={addToCartInProgress || notAllowedToCheckout}
              disabled={addToCartInProgress || !fetchCartForEditReady}
              displayErrors={displayAddonsErrors}
              siblings={siblings}
              onChange={onAddonsChange}
              onUpdate={onAddonsUpdate}
              onValidate={onAddonsValidate}
              getAddonAvailableQuantity={getAddonAvailableQuantity}
            />
          </div>
        )
      }, [
        currency,
        productAddons,
        addonsValue,
        addonsTouched,
        isEdit,
        addToCartInProgress,
        fetchCartForEditReady,
        displayAddonsErrors,
        onAddonsChange,
        onAddonsValidate,
        getAddonAvailableQuantity,
        styles.productAddons]) }
      {
        !isReadOnly && showQuantityInput && (
          <div className={styles.productQuantity}>
            <InputQuantity
              key={`input_qty_${_.get(product, 'id', '')}`}
              onChange={onProductQuantityChange}
              value={productQuantity}
              min={1}
              max={availableQuantity}
              valueEditMode={quantityInputValueEditMode}
            />
          </div>
        )
      }
      {
        initProductReady && (
          <ProductCardAdd
            addToCartButtonState={addToCartButtonState}
            addToCartInProgress={addToCartInProgress}
            availableQuantity={availableQuantity}
            availableForAddToCart={availableForAddToCart}
            className={styles.actionPanel}
            createBackInStockNotificationsInProgress={createBackInStockNotificationsInProgress}
            favourite={_.get(_.find(colorOptions, { id: selectedColorOptionId }), 'favourite')}
            favouritesEnabled={favouritesEnabled}
            hideAddToCartButton={hideAddToCartButton}
            hideSizeVariant={sizeOptionVariantType.hideVariant}
            initProductReady={initProductReady}
            isAddonsValid={isAddonsValidState}
            isEdit={isEdit}
            isOutOfStock={isOutOfStock}
            isReadOnly={isReadOnly}
            onAddToCart={onAddToCart}
            onBackInStockNotification={onBackInStockNotification}
            onFavouriteChange={onFavouriteChange}
            productReady={productReady}
            productQuantity={productQuantity}
            selectedColorOptionId={selectedColorOptionId}
            selectedSizeOptionId={selectedSizeOptionId}
            sellPrice={sellPrice}
            sizeOptions={sizeOptions}
            sku={selectedSku}
          />
        )
      }
      {
        _.get(whatsappAma, 'enable', false)
        && !_.isEmpty(_.toString(_.get(whatsappAma, 'phoneNumber', '')))
        && (
          <WhatsappButton
            className={styles.whatsappAma}
            phoneNumber={_.get(whatsappAma, 'phoneNumber')}
            shareUrl={shareUrl}
          />
        )
      }

      <SocialMedia
        title={product.title}
        imageUrl={shareImageUrl}
        className={styles.shareGroup}
        titleClassName={styles.shareGroupTitle}
        shareUrl={shareUrl}
      />
    </>
  )
}

export default ProductDetailView
