import { createUseStyles } from 'react-jss'
import hexToRgba from '../../../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  '@keyframes loading': {
    from: {
      transform: 'translateX(-100%)',
    },
    to: {
      transform: 'translateX(100%)',
    },
  },
  placeholder: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#eee',
    borderRadius: '1rem',
    userSelect: 'none',
    pointerEvent: 'none',
    '-webkit-touch-callout': 'none',
    '&::after': {
      content: '""',
      display: 'block',
      opacity: 0.25,
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(to right, ${hexToRgba(theme.colors.background, 0)} 0%,${hexToRgba(theme.colors.background, 1)} 50%,${hexToRgba(theme.colors.background, 0)} 100%)`,
      transform: 'translateX(-100%)',
      animationName: '$loading',
      animationDuration: '2s',
      animationTimingFunction: 'ease',
      animationIterationCount: 'infinite',
    },
  },
  productTitle: {
    gridArea: 'title',
    display: 'var(--pages-product-title-display)',
    fontSize: '3rem',
    fontWeight: 500,
    marginBottom: 'var(--pages-product-title-margin-bottom)',
    marginTop: 'var(--pages-product-title-margin-top)',
  },
  productTitlePlaceholder: {
    gridArea: 'title',
    display: 'var(--pages-product-title-display)',
    width: '100%',
    height: '3.75rem',
    marginBottom: 'var(--pages-product-title-margin-bottom)',
    marginTop: 'var(--pages-product-title-margin-top)',
    extend: 'placeholder',
  },
  disclaimerContentGroup: {
    gridArea: 'disclaimerContentGroup',
    display: 'var(--pages-product-disclaimer-content-group-display)',
    marginBottom: 'var(--pages-product-disclaimer-content-group-margin-bottom)',
    marginTop: 'var(--pages-product-disclaimer-content-group-margin-top)',
    '&:empty': {
      display: 'none',
    },
  },
  customLabels: {
    gridArea: 'customLabels',
    display: 'var(--pages-product-custom-labels-display)',
    marginBottom: 'var(--pages-product-custom-labels-margin-bottom)',
    marginTop: 'var(--pages-product-custom-labels-margin-top)',
    '&:empty': {
      display: 'none',
    },
  },
  priceStyle: {
    gridArea: 'price',
    display: 'var(--pages-product-price-display)',
    marginBottom: 'var(--pages-product-price-margin-bottom)',
    marginTop: 'var(--pages-product-price-margin-top)',
    fontSize: '3rem',
  },
  pricePlaceholder: {
    gridArea: 'price',
    display: 'var(--pages-product-price-display)',
    marginBottom: 'var(--pages-product-price-margin-bottom)',
    marginTop: 'max(var(--pages-product-price-margin-top), var(--ui-spacer-xs))',
    width: '13rem',
    height: '3.75rem',
    extend: 'placeholder',
  },
  priceLoadingDots: {
    gridArea: 'price',
    paddingTop: '2.75rem',
    paddingLeft: '0.3rem',
  },
  ratingPlaceholder: {
    gridArea: 'rating',
    display: 'var(--pages-product-rating-display)',
    width: '12rem',
    height: '2rem',
    marginBottom: 'var(--pages-product-rating-margin-bottom)',
    marginTop: 'max(var(--pages-product-rating-margin-top), var(--ui-spacer-xs))',
    // marginBottom: '0.6rem',
    extend: 'placeholder',
  },
  rating: {
    gridArea: 'rating',
    display: 'var(--pages-product-rating-display)',
    marginBottom: 'var(--pages-product-rating-margin-bottom)',
    marginTop: 'var(--pages-product-rating-margin-top)',
    '&:empty': {
      display: 'none',
    },
  },
  productAddPlaceholder: {
    gridArea: 'actionPanel',
    display: 'var(--pages-product-action-panel-display)',
    marginBottom: 'var(--pages-product-action-panel-margin-bottom)',
    marginTop: 'max(var(--pages-product-action-panel-margin-top), var(--ui-spacer-xs))',
    maxWidth: '42rem',
    width: '100%',
    height: '25rem',
    extend: 'placeholder',
  },
  originalPriceStyle: {
    fontSize: '1.5rem',
    fontWeight: 400,
    textDecoration: 'line-through',
    color: theme.colors.bgDark,
    marginRight: '0.2rem',
  },
  sellPriceStyle: {
    fontSize: '3rem',
    fontWeight: 400,
  },
  onSaleSellPriceStyle: {
    color: theme.colors.alert,
  },
  anchorReviewStyle: {
    fontSize: '1.4rem',
    color: theme.colors.primary,
    margin: 0,
    lineHeight: 1.12,
  },
  rateBox: {
    marginTop: '0.2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& div': {
      marginRight: '1rem',
      [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
        marginRight: '1rem',
      },
    },
  },
  colorsBox: {
    gridArea: 'colorOptions',
    display: 'var(--pages-product-color-options-display)',
    marginBottom: 'var(--pages-product-color-options-margin-bottom)',
    marginTop: 'var(--pages-product-color-options-margin-top)',
    '& h5': {
      // marginTop: `${theme.gap}rem`,
      // textTransform: 'uppercase',
      fontSize: '1.5rem',
      fontWeight: 600,
      marginBottom: '1rem',
    },
  },
  colorStyle: {
    height: '5.2rem',
    cursor: 'pointer',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    '&.select': {
      borderColor: theme.colors.secondary,
    },
  },
  sizesBox: {
    gridArea: 'sizeOptions',
    display: 'var(--pages-product-size-options-display)',
    marginBottom: 'var(--pages-product-size-options-margin-bottom)',
    marginTop: 'var(--pages-product-size-options-margin-top)',
    '& h5': {
      // textTransform: 'uppercase',
      fontSize: '1.5rem',
      fontWeight: 600,
      // marginBottom: '1rem',
    },
  },
  sizeTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    // marginTop: '2.5rem',
    '& h5': {
      margin: 0,
    },
  },
  optionsItem: {
    display: 'inline-block',
    marginRight: '1.5rem',
    marginBottom: '1.6rem',
    minWidth: '4.8rem',
  },
  sizeStyle: {
    minHeight: '3.6rem',
    minWidth: '5.1rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: '1.4rem',
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: 2.5,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.colors.bgDark,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      background: theme.colors.bgDark,
      borderColor: theme.colors.bgDark,
    },
  },
  sizeSelectedStyle: {
    borderColor: theme.colors.secondary,
    background: hexToRgba(theme.colors.secondary, 0.5),
    '&:hover': {
      borderColor: theme.colors.secondary,
      background: hexToRgba(theme.colors.secondary, 0.5),
    },
  },
  sizeOutOfStock: {
    backgroundColor: hexToRgba(theme.colors.disabled, 0.2),
    '&:hover': {
      // background: theme.colors.background,
      borderColor: theme.colors.gray500,
    },
  },
  optionsContainer: {
    marginRight: '-2rem',
    marginBottom: '-1.5rem',
  },
  variantNamePlaceholder: {
    width: '8rem',
    height: '2rem',
    marginTop: '3rem',
    marginBottom: '1rem',
    extend: 'placeholder',
    borderRadius: '0.5rem',
  },
  colorOptionsItemPlaceholder: {
    extend: ['optionsItem', 'placeholder'],
    height: '5.2rem',
  },
  sizeOptionsItemPlaceholder: {
    extend: ['optionsItem', 'placeholder'],
    height: '3.7rem',
  },
  availabilityInStore: {
    gridArea: 'availabilityInStore',
    display: 'var(--pages-product-availability-in-store-display)',
    marginBottom: 'var(--pages-product-availability-in-store-margin-bottom)',
    marginTop: 'var(--pages-product-availability-in-store-margin-top)',
  },
  actionPanel: {
    gridArea: 'actionPanel',
    display: 'var(--pages-product-action-panel-display)',
    marginBottom: 'var(--pages-product-action-panel-margin-bottom)',
    marginTop: 'var(--pages-product-action-panel-margin-top)',
  },
  productAddons: {
    gridArea: 'addons',
    display: 'var(--pages-product-addons-display)',
    marginBottom: 'var(--pages-product-addons-margin-bottom)',
    marginTop: 'var(--pages-product-addons-margin-top)',
    maxWidth: '42rem',
  },
  productQuantity: {
    gridArea: 'quantity',
    display: 'var(--pages-product-quantity-display)',
    marginBottom: 'var(--pages-product-quantity-margin-bottom)',
    marginTop: 'var(--pages-product-quantity-margin-top)',
    // marginTop: '1.25rem',
    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',
    maxWidth: '42rem',
    borderTop: `1px solid ${theme.colors.gray500}`,
    borderBottom: `1px solid ${theme.colors.gray500}`,
  },
  guideLink: {
    fontSize: '1.4rem',
    fontWeight: 600,
    textAlign: 'left',
    marginTop: '2rem',
    marginBottom: 0,
    padding: '1rem 2.5rem 1rem 0',
    border: 'none',
    minHeight: 0,
    width: 'auto',
  },
  guideLinkIcon: {
    left: 'auto',
    right: 0,
  },
  shareGroup: {
    gridArea: 'share',
    display: 'var(--pages-product-share-display)',
    marginBottom: 'var(--pages-product-share-margin-bottom)',
    marginTop: 'var(--pages-product-share-margin-top)',
    paddingBottom: '0.4rem',
    '&:empty': {
      display: 'none',
    },
  },
  shareGroupTitle: {
    fontSize: '1.4rem',
    marginBottom: 0,
    fontWeight: 600,
  },
  linkViewGuide: {
    minHeight: 'auto',
    width: 'auto',
    paddingRight: 5,
    textDecoration: 'none',
    '&:hover': {
      color: theme.colors.secondary,
    },
    '& span': {
      color: theme.colors.text,
      fontSize: '1,5rem',
      fontWeight: 600,
    },
    '& img': {
      margin: 0,
      marginLeft: '1rem',
    },
  },
  whatsappAma: {
    gridArea: 'whatsappAma',
    display: 'var(--pages-product-whatsapp-ama-display)',
    marginBottom: 'var(--pages-product-whatsapp-ama-margin-bottom)',
    marginTop: 'var(--pages-product-whatsapp-ama-margin-top)',
    '&:empty': {
      display: 'none',
    },
  },
  whatsappButtonContainer: {
    padding: '0 2rem',
    maxWidth: '42rem',
  },
  whatsappButtonIcon: {
    height: '2rem',
  },
}))

export default useStyles
